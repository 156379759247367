import React from 'react'

import Layout from '../../components/Layout'
import BlogRoll from '../../components/BlogRoll'
import Header from "../../components/Header"
import {Link} from "gatsby";

export default class GeneratorsPage extends React.Component {
  render() {
    return (
      <Layout>
        <Header />
        <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-32 pb-12 md:pt-40 md:pb-20">

          {/* Page header */}
          <div className="max-w-3xl pb-12 md:pb-20 text-center md:text-left">
            <h1 className="h1 mb-4 text-2xl font-bold leading-snug tracking-tight mb-4">Gradient Generators & Other Tools</h1>
            <p className="text-xl text-gray-600">Stay up to date on the latest in web design, and get the best resources from the web design world.</p>
          </div>

          {/* Main content */}
          <div className="md:flex md:justify-between">

            {/* Articles container */}
            <div className="md:flex-grow -mt-4">

              <BlogRoll />

            </div>


          </div>

        </div>
      </div>
    </section>


      </Layout>
    )
  }
}
